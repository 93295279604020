/**
 * @notes: Route constants
 */

export const BASE_ROUTE = "/admin";
export const LOGIN_ROUTE = "/login";
export const SIGNUP_ROUTE = "/welcome";
export const SCANNER_ROUTE = "/scanner";
export const HOME_ROUTE = `/home`;
export const DASHBOARDS_ROUTE = `/dashboards`;
export const USERS_ROUTE = `/users`;
export const TEAMS_ROUTE = `/teams`;
export const LICENSES_ROUTE = `/licenses`;
export const CLIENTS_ROUTE = `/clients`;
export const WORKFLOWS_ROUTE = `/workflows`;
export const LISTS_ROUTE = `/lists`;
export const PLACES_ROUTE = `/map`;
export const PICTURES_ROUTE = "/pictures";
export const DOCUMENTS_ROUTE = "/documents";
export const ENVIRONMENT_ROUTE = `/environment`;
export const ERP_ROUTE = `/erp`;
export const PANORAMA_ROUTE = `/panorama`;
export const CALENDAR_ROUTE = `/calendar`;

export const APP_PATHS = {
  CALENDAR_ROUTE: [CALENDAR_ROUTE],
  HOME_ROUTE: ["/", HOME_ROUTE],
  DASHBOARDS_ROUTE: [
    DASHBOARDS_ROUTE,
    `${DASHBOARDS_ROUTE}/:folder`,
    `${DASHBOARDS_ROUTE}/:folder/create`,
    `${DASHBOARDS_ROUTE}/:folder/:dashboard_id/edit`,
    `${DASHBOARDS_ROUTE}/:folder/:dashboard_id`,
  ],
  USERS_ROUTE: [
    USERS_ROUTE,
    `${USERS_ROUTE}/:subCategory`,
    `${USERS_ROUTE}/:subCategory/:user_id`,
  ],
  CLIENTS_ROUTE: [CLIENTS_ROUTE, `${CLIENTS_ROUTE}/:client_id`],
  ENVIRONMENT_ROUTE: [ENVIRONMENT_ROUTE, `${ENVIRONMENT_ROUTE}/:subCategory`],
  TEAMS_ROUTE: [
    TEAMS_ROUTE,
    `${TEAMS_ROUTE}/:id`,
    `${TEAMS_ROUTE}/:id/edit`,
    `${TEAMS_ROUTE}/create`,
  ],
  WORKFLOWS_ROUTE: [
    WORKFLOWS_ROUTE,
    `${WORKFLOWS_ROUTE}/:workflow_id/edit`,
    `${WORKFLOWS_ROUTE}/:workflow_id`,
    `${WORKFLOWS_ROUTE}/create`,
    `${WORKFLOWS_ROUTE}/activities/:activity_id`,
    `${WORKFLOWS_ROUTE}/activities/:activity_id/edit`,
    `${WORKFLOWS_ROUTE}/activities/:activity_id/view`,
    `${WORKFLOWS_ROUTE}/activities/:activity_id/reports/:report_id`,
    `${WORKFLOWS_ROUTE}/activities/create`,
    `${WORKFLOWS_ROUTE}/jobs/:job_type/:job_id/edit`,
    `${WORKFLOWS_ROUTE}/jobs/:job_type/:job_id`,
    `${WORKFLOWS_ROUTE}/jobs/:job_type/create`,
    `${WORKFLOWS_ROUTE}/jobs/:job_type`,
    `${WORKFLOWS_ROUTE}/triggers`,
    `${WORKFLOWS_ROUTE}/triggers/create`,
    `${WORKFLOWS_ROUTE}/triggers/:trigger_id`,
    `${WORKFLOWS_ROUTE}/triggers/:trigger_id/edit`,
    `${WORKFLOWS_ROUTE}/webhooks`,
    `${WORKFLOWS_ROUTE}/webhooks/create`,
    `${WORKFLOWS_ROUTE}/webhooks/:webhook_id`,
    `${WORKFLOWS_ROUTE}/webhooks/:webhook_id/edit`,
  ],
  LISTS_ROUTE: [
    LISTS_ROUTE,
    `${LISTS_ROUTE}/:list_id`,
    `${LISTS_ROUTE}/:list_id/edit`,
    `${LISTS_ROUTE}/:list_id/view`,
    `${LISTS_ROUTE}/create`,
  ],
  PANORAMA_ROUTE: [
    PANORAMA_ROUTE,
    `${PANORAMA_ROUTE}/balances`,
    `${PANORAMA_ROUTE}/customers`,
    `${PANORAMA_ROUTE}/customer_groups`,
    `${PANORAMA_ROUTE}/discounts`,
    `${PANORAMA_ROUTE}/distributors`,
    `${PANORAMA_ROUTE}/lines`,
    `${PANORAMA_ROUTE}/orders`,
    `${PANORAMA_ROUTE}/products`,
    `${PANORAMA_ROUTE}/product_groups`,
    `${PANORAMA_ROUTE}/prices`,
    `${PANORAMA_ROUTE}/recommendations`,
    `${PANORAMA_ROUTE}/stock`,
    `${PANORAMA_ROUTE}/warehouses`,
  ],
  ERP_ROUTE: [
    `${ERP_ROUTE}/orders`,
    `${ERP_ROUTE}/stock`,
    `${ERP_ROUTE}/orders/:orderId`,
    `${ERP_ROUTE}/discounts`,
  ],
  PLACES_ROUTE: [PLACES_ROUTE, `${PLACES_ROUTE}/:id`],
  PICTURES_ROUTE: [PICTURES_ROUTE],
};
